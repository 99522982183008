import { formatDate } from "../../../utils/helpers/dateTimeHelpers";
import { BunkerProductType } from "../../../utils/enums";
import { getBunkerFactorLabels } from "../components/BunkersLifted/BunkersLiftedBDNFiguresField/useBDNFiguresFieldFunctionality";

export const validateForm = (
  currentPortcall,
  previousPortcall,
  bunkerFactorsValues,
  commercialBunkerFactorPreferences
) => {
  const newErrObj = {};
  const newHighlightedPortcallsFieldsObj = {};
  const bunkerFactorsErrors = {};
  const { bunkerProductTypesWithImmediateFactorsRequirement } =
    commercialBunkerFactorPreferences ?? {};

  if (currentPortcall.endOfSeaPassage) {
    if (previousPortcall && !previousPortcall?.startOfSeaPassage) {
      newErrObj.endOfSeaPassage =
        'Cannot be set while previous portcall "Start of Sea Passage" was not set yet.';
      newHighlightedPortcallsFieldsObj[previousPortcall?.portCallId] = [
        "startOfSeaPassage",
      ];
    }
  }
  const arrivalTugsExists =
    currentPortcall.arrivalTugs.tugs != null &&
    currentPortcall.arrivalTugs.tugs !== "";

  if (currentPortcall.endOfSeaPassage || arrivalTugsExists) {
    const tugs =
      currentPortcall.endOfSeaPassage && !arrivalTugsExists
        ? "Must be set"
        : Number(currentPortcall.arrivalTugs.tugs) > 4
        ? "Max tugs number is 4"
        : undefined;
    const tugsReason =
      Number(currentPortcall.arrivalTugs.tugs) > 0 &&
      !currentPortcall.arrivalTugs.tugsReason
        ? "Tugs reason is required"
        : undefined;
    const tugsSubReason =
      currentPortcall.arrivalTugs.tugsReason == 2000 &&
      !currentPortcall.arrivalTugs.tugsSubReason
        ? "Master reason is required"
        : undefined;

    if (tugs || tugsReason || tugsSubReason) {
      newErrObj.arrivalTugs = {
        ...(tugs && { tugs }),
        ...(tugsReason && { tugsReason }),
        ...(tugsSubReason && { tugsSubReason }),
      };
    }
  }

  const departureTugsExists =
    currentPortcall.departureTugs.tugs != null &&
    currentPortcall.departureTugs.tugs !== "";

  if (currentPortcall.startOfSeaPassage || departureTugsExists) {
    const tugs =
      currentPortcall.startOfSeaPassage && !departureTugsExists
        ? "Must be set"
        : Number(currentPortcall.departureTugs.tugs) > 4
        ? "Max tugs number is 4"
        : undefined;
    const tugsReason =
      Number(currentPortcall.departureTugs.tugs) > 0 &&
      !currentPortcall.departureTugs.tugsReason
        ? "Tugs reason is required"
        : undefined;
    const tugsSubReason =
      currentPortcall.departureTugs.tugsReason == 2000 &&
      !currentPortcall.departureTugs.tugsSubReason
        ? "Master reason is required"
        : undefined;

    if (tugs || tugsReason || tugsSubReason) {
      newErrObj.departureTugs = {
        ...(tugs && { tugs }),
        ...(tugsReason && { tugsReason }),
        ...(tugsSubReason && { tugsSubReason }),
      };
    }
  }

  if (currentPortcall.startOfSeaPassage) {
    if (!currentPortcall.endOfSeaPassage) {
      newErrObj.startOfSeaPassage =
        'Cannot be set while "End of Sea Passage" is not set';
      newHighlightedPortcallsFieldsObj[currentPortcall.portCallId] = [
        "endOfSeaPassage",
      ];
    }

    if (
      currentPortcall.endOfSeaPassage &&
      currentPortcall.startOfSeaPassage < currentPortcall.endOfSeaPassage
    ) {
      newErrObj.startOfSeaPassage = 'Cannot be less than "End of Sea Passage"';
    }
  }
  if (
    currentPortcall.eta &&
    currentPortcall.etd &&
    currentPortcall.eta > currentPortcall.etd
  ) {
    newErrObj.eta = "Cannot be later than ETD";
    newErrObj.etd = "Cannot be earlier than ETA";
  }

  if (
    currentPortcall.observedDistanceMandatory &&
    currentPortcall.endOfSeaPassage &&
    !currentPortcall.observedDistance
  ) {
    newErrObj.observedDistance = 'Required with provided "End of Sea Passage"';
  }

  if (
    currentPortcall.observedDistanceMandatory &&
    currentPortcall.startOfSeaPassage &&
    !currentPortcall.observedInPortDistance
  ) {
    newErrObj.observedInPortDistance =
      'Required with provided "Start of Sea Passage"';
  }

  if (
    currentPortcall?.earlyDelayArrival?.reasons?.includes(5000) &&
    (currentPortcall?.earlyDelayArrival?.comment == null ||
      currentPortcall?.earlyDelayArrival?.comment == "")
  ) {
    newErrObj.earlyDelayArrival =
      "Comment is required when other reason is selected.";
  }

  const bunkersWhichRequireImmediateFactorsInPort = bunkerFactorsValues.filter(
    (bunkerProduct) =>
      bunkerProductTypesWithImmediateFactorsRequirement.includes(
        bunkerProduct.productType
      )
  );
  bunkersWhichRequireImmediateFactorsInPort.forEach((bunkerProduct) => {
    bunkerProduct.bunkerFactors.forEach((bunkerFactor) => {
      if (!bunkerFactor.value) {
        const { label } = getBunkerFactorLabels(bunkerFactor.type);
        newErrObj[`${BunkerProductType[bunkerProduct.productType]}-${label}`] =
          "Value is required";
        bunkerFactorsErrors[bunkerProduct.productType] = true;
      }
    });
  });

  const areAnyErrors = Object.keys(newErrObj).length;
  const areAnyBunkerFactorsErrors = Object.keys(bunkerFactorsErrors).length;
  return {
    isValid: !areAnyErrors,
    errorsObj: newErrObj,
    highlightedPortcallFieldsObj: newHighlightedPortcallsFieldsObj,
    bunkerFactorsIsValid: !areAnyBunkerFactorsErrors,
  };
};

export const formatPayload = (
  portCallState,
  bunkerFactorsState,
  isFillingRequired
) => {
  const liftedBunkerProductsInPort =
    portCallState.liftedBunkerProductsInPort.map((bunkerProduct) => {
      const bunkerFactors = bunkerFactorsState.find(
        (bunkerFactor) => bunkerFactor.productType === bunkerProduct.type
      );
      return {
        ...bunkerProduct,
        type: bunkerProduct.type,
        bunkerFactors: isFillingRequired
          ? bunkerFactors.bunkerFactors.map((factor) => ({
              bunkerFactorType: factor.type,
              value: factor.value ? Number(factor.value) : null,
            }))
          : [],
      };
    });
  return {
    arrivalDraft: portCallState?.arrivalDraft,
    arrivalTugs: portCallState?.arrivalTugs,
    departureDraft: portCallState?.departureDraft,
    departureTugs: portCallState?.departureTugs,
    endOfSeaPassage: portCallState?.endOfSeaPassage,
    eta: portCallState?.eta,
    etd: portCallState?.etd,
    isChangedETD: portCallState?.isChangedETD,
    portCallId: portCallState?.portCallId,
    remarks: portCallState?.remarks,
    startOfSeaPassage: portCallState?.startOfSeaPassage,
    cargoes: portCallState?.cargoes?.map((cargo) => ({
      billOfLadingStatus: cargo?.billOfLadingStatus,
      portCallCargoId: cargo?.portCallCargoId,
    })),
    bunkersRemainingOnBoardOnArrival:
      portCallState?.bunkersRemainingOnBoardOnArrival,
    bunkersRemainingOnBoardOnDeparture:
      portCallState?.bunkersRemainingOnBoardOnDeparture,
    freshWaterOnArrival: portCallState?.freshWaterOnArrival,
    freshWaterOnDeparture: portCallState?.freshWaterOnDeparture,
    earlyDelayArrival: portCallState?.earlyDelayArrival,
    observedDistance: portCallState?.observedDistance,
    observedInPortDistance: portCallState?.observedInPortDistance,
    liftedBunkerProductsInPort: liftedBunkerProductsInPort.map(
      ({ bdnFiguresIsExist, ...rest }) => rest
    ),
  };
};

export const generateEtaWarningMessage = (
  earliestEndOfLaycan,
  eta,
  commercialManagerOrgName
) => {
  if (!earliestEndOfLaycan || !eta) return "";
  let warningMessage = "";
  const pattern = /Z$/;
  const earliestEndOfLaycanFormat = pattern.test(earliestEndOfLaycan)
    ? earliestEndOfLaycan
    : `${earliestEndOfLaycan}Z`;
  if (new Date(eta) > new Date(earliestEndOfLaycanFormat)) {
    warningMessage = `The provided ETA is later than the laycan on ${formatDate(
      earliestEndOfLaycan
    )}. Please make sure to confirm with ${commercialManagerOrgName} that this ETA may be communicated.`;
  }
  return warningMessage;
};
